<template>
  <div>
    <title>SIM INVENTORY ~ PRODUCT PROCESS DETAILS BOM MATERIAL</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Product Process Details BOM Material
        <br />
        <h4>
          The following is a list of Product Process Details BOM Material
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div v-if="addData" class="col-md-5">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">
                Save Product Process Details BOM Material
              </h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Item Code</label>
                    <input
                      type="text"
                      v-model="isidata.kodebarang"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Item Code"
                    />
                    <input
                      type="hidden"
                      v-model="isidata.idxprodprosbommat"
                      autocomplete="off"
                      class="form-control"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getbarang()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.namabarang"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.notesprosesprodm"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="text"
                    v-model="isidata.qtyprosesdmi"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Qty"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit</label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.satuan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Unit"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <div v-if="isFrmeditData" class="col-md-5">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">
                Update Product Process Details BOM Material
              </h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Item Code</label>
                    <input
                      type="text"
                      v-model="isidata.kodebarang"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Item Code"
                    />
                    <input
                      type="hidden"
                      v-model="isidata.idxprodprosbommat"
                      autocomplete="off"
                      class="form-control"
                    />
                    <input
                      type="hidden"
                      v-model="isidata.idxproddprosbom"
                      autocomplete="off"
                      class="form-control"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getbarang()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.namabarang"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.notesprosesprodm"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="text"
                    v-model="isidata.qtyprosesdmi"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Qty"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit</label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.satuan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Unit"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <button @click="awaldepan()" class="btn btn-success">
                  <i class="fa fa-angle-double-left"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-7">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Product Process Details BOM Material</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Item Name"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Qty</th>
                      <th>Unit</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.nama_barang }}</td>
                      <td>{{ classdata.qty }}</td>
                      <td>{{ classdata.unit }}</td>
                      <td>{{ classdata.notes }}</td>
                      <td>
                        <button
                          @click="EditData(classdata.id)"
                          title="Edit Item"
                          class="btn btn-info"
                        >
                          <i class="fa fa-edit"></i></button
                        ><br><p></p>
                        <!--<router-link
                          :to="'/edit-prosesprodukbommaterial-detail/' + classdata.id"
                        >
                          <button
                            title="Edit Product Process Details BOM Material"
                            class="btn btn-info"
                          >
                            <i class="fa fa-edit"></i>
                          </button> </router-link
                        ><br><p></p>-->
                        <button
                          title="Delete Product Process Details BOM Material"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="nmsrc">
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div>
                <div v-else></div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination
  },
  data() {
    return {
      isidata: {
        kodebarang: "",
        namabarang: "",
        notesprosesprodm: "",
        qtyprosesdmi: "",
        satuan: "",
        idxprodprosbommat: "",
        idxproddprosbom: ""
      },
      addData: true,
      isFrmeditData: false,
      nmsrc: true,
      loading: false,
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchData();
    sessionStorage.setItem("idprodprosbommat", this.$route.params.id);
    this.isidata.idxprodprosbommat = sessionStorage.getItem("idprodprosbommat");
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    awaldepan() {
      this.addData = true;
      this.isFrmeditData = false;
      this.addAlias = false;
      this.isidata.kodebarang = "";
      this.isidata.namabarang = "";
      this.isidata.notesprosesprodm = "";
      this.isidata.qtyprosesdmi = "";
      this.isidata.satuan = "";
      this.isidata.idxprodprosbommat = "";
      this.fetchData();
    },
    EditData(id) {
      this.isFrmeditData = true;
      this.addData = false;
      this.loading = true;
      const urlAPIget =
        this.$apiurl +
        "produk_d_bom_proses_material/getproduk_d_bom_proses_materialbyid?id=" +
        id;

      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.isidata.idxprodprosbommat = resp.data.data.id;
          this.isidata.idxproddprosbom = resp.data.data.id_produk_d_bom_proses;
          this.isidata.kodebarang = resp.data.data.kode_barang;
          this.isidata.satuan = resp.data.data.unit;
          this.isidata.notesprosesprodm = resp.data.data.notes;
          this.isidata.qtyprosesdmi = resp.data.data.qty;
          this.isidata.satuan = resp.data.data.unit;
          this.loading = false;

          const urlapigetb =
            this.$apiurl +
            "barang/getbarangbykode_barang?kode_barang=" +
            this.isidata.kodebarang;

          axios
            .get(urlapigetb, { headers })
            .then((respn) => {
              if (respn.data.pesan == "Data not found") {
                this.namabarang = "";
              } else {
                this.isidata.kodebarang = resp.data.data.kode_barang;
                this.isidata.namabarang = resp.data.data.nama_barang;
              }
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async getbarang() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getbarangbykode_barang?kode_barang=" + this.isidata.kodebarangbomd;
      const urlAPIget =
        this.$apiurl +
        "barang/getbarangbykode_barang?kode_barang=" +
        this.isidata.kodebarang;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Item Code not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.kodebarang = resp.data.data.kode_barang;
            this.isidata.namabarang = resp.data.data.nama_barang;
            this.isidata.satuan = resp.data.data.unit;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async updateData() {
      this.loading = true;
      var kdbrg = this.isidata.kodebarang;
      var nmbrg = this.isidata.namabarang;
      var catat = this.isidata.notesprosesprodm;
      var qty = this.isidata.qtyprosesdmi;
      var sat = this.isidata.satuan;
      if (kdbrg == "" || nmbrg == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item Code can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qty == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (sat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          id_produk_d_bom_proses: this.isidata.idxprodprosbommat,
          kode_barang: this.isidata.kodebarang,
          notes: this.isidata.notesprosesprodm,
          qty: this.isidata.qtyprosesdmi,
          kode_user: kodeuser,
          unit: this.isidata.satuan
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIUpdateData =
          this.$apiurl +
          "produk_d_bom_proses_material/updateproduk_d_bom_proses_material/" +
          this.isidata.idxprodprosbommat;
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdateData, paramdata, { headers: headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
              this.addData = true;
              this.isidata.kodebarang = "";
              this.isidata.namabarang = "";
              this.isidata.notesprosesprodm = "";
              this.isidata.qtyprosesdmi = "";
              this.isidata.satuan = "";
              this.isidata.idxprodprosbommat = "";
              this.isFrmeditData = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async saveData() {
      this.loading = true;
      var kdbrg = this.isidata.kodebarang;
      var nmbrg = this.isidata.namabarang;
      var catat = this.isidata.notesprosesprodm;
      var qty = this.isidata.qtyprosesdmi;
      var sat = this.isidata.satuan;
      if (kdbrg == "" || nmbrg == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item Code can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qty == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (sat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          id_produk_d_bom_proses: this.isidata.idxprodprosbommat,
          kode_barang: this.isidata.kodebarang,
          notes: this.isidata.notesprosesprodm,
          qty: this.isidata.qtyprosesdmi,
          kode_user: kodeuser,
          unit: this.isidata.satuan
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPISaveData =
          this.$apiurl +
          "produk_d_bom_proses_material/saveproduk_d_bom_proses_material";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPISaveData, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };

      const urlApiGETdetail =
        this.$apiurl +
        "produk_d_bom_proses_material/getalldataproduk_d_bom_proses_material?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&id_produk_d_bom_proses=" +
        this.$route.params.id;
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          this.list_paged = resp.data.data;
          this.count = resp.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          }).then(function(isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
    searchdata() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const paramsst = this.getRequestParams(this.pagestat);
      if (this.namesearch == "") {
        window.location.reload();
        this.loading = true;
        this.fetchData();
      } else {
        const tokenlogin = sessionStorage.getItem("token");
        const urlApiGETdetail =
          this.$apiurl +
          "produk_d_bom_proses_material/getalldataproduk_d_bom_proses_material?length=" +
          this.pageSize +
          "&cari=" +
          this.namesearch +
          "&page=" +
          this.page +
          "&id_produk_d_bom_proses=" +
          this.$route.params.id;
        axios
          .get(urlApiGETdetail, {
            paramsst,
            headers: { Authorization: tokenlogin }
          })
          .then((resp) => {
            this.list_paged = resp.data.data;
            this.count = resp.data.data.datatotalcount;
            this.loading = false;
          })
          .catch((err) => {
            // console.log(err.response);
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data
            }).then(function(isConfirm) {
              if (isConfirm) {
                window.location.href = "/";
              }
            });
          });
      }
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIbrgdel =
            this.$apiurl +
            "produk_d_bom_proses_material/hapusproduk_d_bom_proses_material/" +
            id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              }).then(function(isConfirm) {
                if (isConfirm) {
                  window.location.href = "/";
                }
              });
            });
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
